.myContainer {
  margin-top: 55px;
}

.myToggle {
  float: right;
}

.myToggle span {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: normal;
}

/*Cart Start*/
.yt-cart-price-lister {
  padding: 24px 40px;
  margin-bottom: 24px;
  /* margin-top: 35px; */
}

.cart-prodict-amount th {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-black);
}

.cart-prodict-amount thead th {
  padding-top: 0;
  padding-bottom: 5px;
  padding-left: 0;
  padding-right: 18px;
}

.cart-pg-title {
  font-size: 24px !important;
  font-weight: bold !important;
  line-height: 1.13 !important;
  color: var(--color-black) !important;
}

.cart-prodict-amount tbody td,
.cart-prodict-total-amount tbody td,
.ccart-prodict-sub-total-amount tbody td,
.cart-prodict-sub-total-amount tbody td {
  padding: 0px 0;
}

.cart-prodict-amount thead th:last-child {
  text-align: right;
}

.cart-product-amount-ttl {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.color-dark {
  color: var(--color-black) !important;
}

.cart-product-amount-price {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #000000;
  display: block;
  margin-left: 4px;
}

.homepage-cart-divider {
  border-bottom: solid 1px #e2e2e2 !important;
  display: block;
}

.cart-coupon .form-group {
  position: relative;
}

.cart-coupon .form-control {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}

.cart-coupon-btn {
  position: absolute !important;
  top: 50% !important;
  right: 12px !important;
  opacity: 0.99 !important;
  border-radius: 5px !important;
  background-color: var(--button-primary-hover) !important;
  color: var(--button-text-primary-hover) !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  transform: translateY(-50%) !important;
  line-height: 1.15 !important;
  padding: 8px 15px !important;
}

.cart-coupon-btn:hover {
  transition: 0.5s ease;
  background-color: var(--button-primary) !important;
  color: var(--button-text-primary) !important;
}

.cart-coupon .error .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.01 !important;
  text-align: left;
  color: var(--color-orange);
}

.cart-coupon .success .cart-coupon-code-message {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}

.cart-coupon-change-btn {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}

.cart-coupon .cart-coupon-bottom-wrapper {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
  top: 5rem;
}

.cart-coupon .error .cart-coupon-bottom-wrapper,
.cart-coupon .success .cart-coupon-bottom-wrapper {
  opacity: 1;
}

form.yt-cart-disct-wrap .yt-form-cpn-err.form-group,
form.yt-cart-disct-wrap .success.form-group {
  margin-bottom: 20px !important;
}

.availableCoupons {
  color: #3b5998;
  display: block;
  cursor: pointer;
}

.rewards-max-quantity {
  color: grey !important;
}

.availableCoupons:hover {
  transition: 0.5s ease;
  text-decoration: underline;
}

.cart-product {
  display: flex;
  flex-direction: column;
}

.cart-customization {
  font-size: 15px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.cart-customization span {
  color: #f67054;
}

.credit-div {
  display: flex;
}

.credit-div span {
  width: 100%;
}

.cart-proceed {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 1.11 !important;
  text-align: center !important;
  background-color: #364f6b !important;
  color: #fff;
  border: none !important;
  /* width:100%; */
}

.cart-proceed:hover {
  transition: 0.5s ease;
  border-color: #364f6b !important;
  background-color: #576c85 !important;
  color: #fff !important;
}

.btn-proceed {
  width: auto;
  height: 48px;
  margin: auto;
}

.my-span {
  color: #7c8188;
  font-size: 15px;
}

.my-span label {
  color: #ffa500;
}

.reward-balance-span {
  font-size: 14px;
  line-height: 1.43;
  color: var(--color-gray);
}

.reward-balance-span label {
  color: var(--color-gold-yellow);
}

.cart-coupon-change-btn {
  color: #3b5998 !important;
  cursor: pointer !important;
}

.cart-coupon-change-btn:hover {
  text-decoration: underline !important;
}

.my-cart-engagements {
  display: flex;
  justify-content: space-between;
}

.my-cart-applied-code {
  display: flex;
  justify-content: space-between;
}

.applied-coupon-code {
  color: #6cb83a;
  display: block;
}

.applied-coupon-code svg {
  fill: red;
  padding-bottom: 4px;
  font-size: 20px;
  cursor: pointer;
}

.my-cart-length {
  font-weight: normal;
  font-size: 18px;
  color: gray;
}

.quantity-input {
  /* background-color: var(--button-primary-hover) !important;
  color: white !important; */
  color: #000 !important;
  height: 30px !important;
  width: 98px !important;
}

.product-cart-amount {
  width: 100px;
  display: flex;
  float: right;
  justify-content: end;
}

.my-order-cart-title {
  padding: 9px 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #1c213a;
}

.remove-item-svg {
  color: #FC5185;
  margin-right: 7px;
  font-size: 30px;
  cursor: pointer;
  height: 15px;
  width: 15px;
}

.remove-item-svg:hover {
  transition: all 0.5s ease;
  transform: rotate(360deg);
}

/*Cart End*/

/*Coupon Modal Start*/
.coupon-title-bar .modal-title {
  margin: 0;
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: var(--color-dark-grey);
}

.coupon-title-bar {
  border-bottom: 1px solid #dbdbdb !important;
  padding-bottom: 25px;
}

.noCoupon {
  text-align: center;
}

.coupon-body-text:not(:first-child) {
  padding-top: 18px;
}

.coupon-body-text:not(:last-child) {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 18px;
}

.coupon-body-text {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
  user-select: none;
}

.coupon-body-text p {
  color: #264653;
  font-size: 18px;
  font-weight: 500;
}

.coupon-body-text p.active {
  color: green;
}

.coupon-body-text p svg {
  color: #a3a3a3;
  font-size: 23px;
  float: right;
  cursor: pointer;
}

.coupon-body-text p svg.active {
  color: green;
}

.coupon-title-bar span {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}

.pp-log-out-btn-light-grey {
  color: var(--color-dark-grey);
}

.pp-log-out-btn-dark-grey {
  color: var(--color-black);
}

.log-out-bottom-bar {
  position: relative;
  border-top: 2px solid #dbdbdb;
}

.yt-form-spacer {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}

.yt-coupon-wrap.modal-body {
  padding: 30px 15px 30px;
}

.cart-prodict-info .food-subtitle {
  font-size: 14px;
  color: #000000;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  margin: 0;
  min-height: 19px;
}

.my-toggle-class {
  display: flex;
}

.food-subtitle::-webkit-scrollbar {
  width: 2px;
}

.food-subtitle::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  width: 1px;
}

.food-subtitle::-webkit-scrollbar-thumb {
  background: var(--color-gray);
}

.food-subtitle::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-grey);
}

.pl-8 {
  padding-left: 8px;
}

.mb-40 {
  margin-bottom: 40px;
}

/*Coupon Modal End*/

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-cart-price-lister {
    /*  Column Three  Cart */
    padding: 24px 40px;
  }

  .cart-prodict-amount tbody tr:last-child td {
    /*  Column Three  Cart */
    padding-bottom: 20px;
  }

  table.yt-sub-ttl-tbl-wrap.table tr:first-child td {
    /*  Column Three  Cart */
    padding-top: 20px;
  }

  table.yt-sub-ttl-tbl-wrap.table {
    /*  Column Three  Cart */
    margin: 0;
  }

  .homepage-cart-divider {
    /*  Column Three  Cart */
    /* margin: 10px 0 !important; */
  }

  form.yt-cart-disct-wrap {
    /*  Column Three  Cart */
    padding: 10px 0 !important;
  }
}

/* @media (max-width: 1366px){
  .btn-proceed {
    width: 320px; 
    height: 48px; 
    margin: auto;
  }
} */

@media (max-width: 1279.92px) {
  .yt-cart-price-lister {
    /*  Column Three  Cart */
    padding: 20px 15px;
  }

  .cart-prodict-amount thead th:first-child {
    /*  Column Three  Cart */
    font-size: 18px;
    line-height: 1.11;
  }

  .cart-prodict-amount thead th:last-child {
    /*  Column Three  Cart */
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
  }

  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    /*  Column Three  Cart */
    padding: 0px 0;
  }

  .cart-prodict-amount thead th:first-child {
    /*  Column Three  Cart */
    font-size: 16px;
    line-height: 1.19;
  }

  .cart-prodict-amount thead th:last-child {
    /*  Column Three  Cart */
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
  }

  .cart-product-amount-ttl {
    /*  Column Three  Cart */
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }

  .cart-product-amount-price {
    /*  Column Three  Cart */
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
  }

  .homepage-cart-divider {
    /*  Column Three  Cart */
    /* margin: 15px 0; */
  }

  .cart-coupon .form-control {
    /*  Column Three  Cart */
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }

  .cart-coupon .error .cart-coupon-code-message {
    /*  Column Three  Cart */
    font-size: 12px;
    line-height: 1.58;
  }

  .cart-coupon-change-btn {
    /*  Column Three  Cart */
    font-size: 14px;
    line-height: 1.14;
  }

  .coupon-title-bar span {
    /*Coupon Modal*/
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
  }

  .coupon-body-text {
    /*Coupon Modal*/
    font-size: 16px;
    line-height: 1.13;
  }
}

@media (min-width: 768px) {

  .cart-prodict-amount tbody td,
  .cart-prodict-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td,
  .cart-prodict-sub-total-amount tbody td {
    /*  Column Three  Cart */
    padding: 0px 0;
  }
}

@media (max-width: 767.92px) {
  .yt-cart-price-lister {
    /*  Column Three  Cart */
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
  }

  .cart-product-amount-ttl {
    /*  Column Three  Cart */
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
  }

  .homepage-cart-divider {
    /*  Column Three  Cart */
    /* margin: 3px 0 7px; */
  }

  .cart-coupon .form-control {
    /*  Column Three  Cart */
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
  }

  .cart-product-amount-price {
    /*  Column Three  Cart */
    font-size: 15px;
    line-height: 1.2;
    font-weight: normal;
  }

  .coupon-title-bar span {
    /*Coupon Modal*/
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
  }

  .yt-coupon-wrap.modal-body {
    /*Coupon Modal*/
    padding: 22px 5px 30px;
  }

  .coupon-body-text {
    /*Coupon Modal*/
    font-size: 15px;
    line-height: 1.2;
  }
}

.adv-img {
  width: 100%;
  height: 250px !important;
  border-radius: 5px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .adv-img {
    width: 100%;
    height: auto !important;
    border-radius: 5px;
  }
}

/* Quantity input */
.qty-input {
  color: #000;
  background: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.qty-input .product-qty,
.qty-input .qty-count {
  background: transparent;
  color: inherit;
  font-weight: 400;
  font-size: inherit;
  border: none;
  display: inline-block;
  min-width: 0;
  height: 2.5rem;
  line-height: 1;
}

.qty-input .product-qty:focus,
.qty-input .qty-count:focus {
  outline: none;
}

.qty-input .product-qty {
  width: 38px;
  height: 30px;
  min-width: 0;
  display: inline-block;
  border: 1px solid #DFDFDF;
  text-align: center;
  appearance: textfield;
}

.qty-input .product-qty::-webkit-outer-spin-button,
.qty-input .product-qty::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.qty-input .qty-count {
  padding: 0;
  cursor: pointer;
  width: 2.5rem;
  font-size: 1.25em;
  text-indent: -100px;
  overflow: hidden;
  position: relative;
}

.qty-input .qty-count--minus:before,
.qty-input .qty-count--minus:after {
  content: "";
  height: 2px;
  width: 10px;
  position: absolute;
  display: block;
  background: #757575;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-weight: 400;
  font-size: 24px;
}

.qty-input .qty-count--add:before,
.qty-input .qty-count--add:after {
  content: "";
  height: 2px;
  width: 10px;
  position: absolute;
  display: block;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.qty-input .qty-count--minus {
  height: 30px;
  width: 30px;
  border-right: 1px solid #e2e2e2;
  background: #DFDFDF;
  border-radius: 5px 0px 0px 5px;
}

.qty-input .qty-count--add {
  height: 30px;
  width: 30px;
  border-left: 1px solid #e2e2e2;
  background: #3FC1CB;
  border-radius: 5px 0px 0px 5px;
  transform: rotate(-180deg);

}

.qty-input .qty-count--add:after {
  transform: rotate(90deg);
}