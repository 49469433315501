.mt-10 {
  margin-top: 10px;
}

.smt-btn {
  font-size: 18px !important;
}

.labelText {
  color: var(--color-gray);
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

.yt-forgot-pw-wrap {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.send-otp-btn {
  background-color: #364f6b !important;
  border-color: #364f6b !important;
  color: #fff;
}

.send-otp-btn:hover {
  border-color: #364f6b !important;
  background-color: #576c85 !important;
  color: #fff !important;
}

.yt-fpp-mb-5 {
  margin-bottom: 50px;
}

.yt-fpp-mt-5 {
  margin-top: 50px;
}

.yt-fp-top-back-tag-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: #000;
}

.yt-fp-back-icn {
  font-size: 40px;
  margin-left: -12px;
  color: var(--color-black) !important;
}

.yt-fp-bg-banner {
  /* background-image: url(../login-image.jpg); */
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}

.yt-fp-other-info {
  position: absolute;
  right: 40px;
  bottom: 10%;
}

.yt-fp-oi-tag-small {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}

.yt-fp-oi-tag-big {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}

.yt-fp-bdr {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}

.yt-fp-sp-now {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}

.yt-fp-tag-line {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black) !important;
  margin-bottom: 40px;
  margin-top: 16px;
}

.yt-fp-tag-line-2 {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px !important;
}

.yt-fp-form {
  position: relative;
}

.yt-fp-form .form-group {
  position: relative;
  margin-bottom: 30px !important;
}

.yt-fp-icn {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.yt-fp-icn2 {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}

.yt-fp-form {
  margin-bottom: 30px;
}

.yt-fp-form .form-group .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  border-bottom-style: groove;
}

.yt-fp-btn {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  /* color: var(--button-text-primary); */
  color: var(--color-ButtonTextRegular);
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-ButtonBackRegular);
  /* background-color: var(--button-primary); */
  border-color: var(--button-primary);
}

.yt-fp-btn:hover {
  background-color: var(--color-ButtonBackHover);
  color: var(--color-ButtonTextHover);
}

.yt-fp-skip-btn {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}

.otp-timer {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}

.yt-resent-otp-btn {
  font-size: 22px;
  line-height: 1.14;
  /* color: var(--color-orange); */
  color: var(--color-ButtonTextRegular);
  text-decoration: none !important;
  box-shadow: none !important;
  padding: 0;
}

.yt-fp-form .error.form-group .form-control {
  border: solid 1px var(--color-orange);
}

.yt-optp-message-show {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.yt-fp-form .error.form-group .yt-optp-message-show {
  color: var(--color-orange);
  display: block;
}

.yt-pas-eye-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-pas-eye-icn2 {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-fp-form .success.form-group .yt-optp-message-show {
  color: var(--color-green);
  display: block;
}

.error.form-group,
.success.form-group {
  margin-bottom: 43px !important;
}

.invalid {
  color: var(--color-orange);
}

.invalid-input {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}

.normal-input:active {
  border: 1px solid var(--color-blue) !important;
}

.yt-forgot-pass-vie-icn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.yt-forgot-pass-vie-icn2 {
  position: absolute;
  /* top: 33%; */
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}

.pass_success {
  color: green;
}

.yt-fp-inner-content.yt-banner-col {
  width: 45%;
}

.yt-fp-inner-content.yt-form-col {
  padding-left: 0px;
  width: 100%;
}

.invalid {
  color: var(--color-orange);
}

.invalid-feedback {
  font-size: 12px;
  line-height: 1.17;
}

/**/
.yt-etpass-msg {
  line-height: 1.1;
  font-size: 13.5px;
  margin-top: -1rem;
  position: absolute;
  word-break: break-all;
  /* bottom: 39px; */
}

form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group .invalid-feedback {
  bottom: -15px;
}

form.yt-new-pass-form-wrap {
  position: relative;
}

@media (min-width: 1400px) {

  .yt-frgt-row .yt-banner-col,
  .yt-frgt-row .yt-form-col {
    width: 100%;
  }

  .yt-forgot-pw-wrap {
    max-width: 600px;
  }

  .col-xxl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

/*
    * Screen for under 1280 Resolution 
    */

@media (max-width: 1279.92px) {
  .yt-forgot-pw-wrap {
    max-width: 588px;
    padding: 0;
  }

  .yt-frgt-row .yt-fp-inner-content {
    width: 100%;
  }

  .yt-frgt-row .yt-fp-bg-banner {
    order: 2;
  }

  .yt-fp-inner-content.yt-form-col {
    padding: 32px 30px 30px;
  }

  .yt-fp-top-back-tag-name {
    font-size: 18px;
    line-height: 1.11;
    font-weight: bold;
    color: var(--color-primary) !important;
  }

  .yt-fp-tag-line {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
  }

  .yt-fp-tag-line-2 {
    margin-bottom: 34px !important;
    margin-top: 0;
  }

  .yt-fp-form .form-group .form-control {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
  }

  .yt-fp-form .form-group {
    margin-bottom: 20px;
  }

  .yt-fp-btn {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .yt-fp-bg-banner {
    min-height: 211px;
    background-position: 100% 35%;
  }

  .yt-fp-oi-tag-small {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
  }

  .yt-fp-oi-tag-big {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
  }

  .yt-fp-sp-now {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
  }

  .yt-fp-bdr {
    bottom: 55px !important;
  }
}

/*
    * Screen for under 768 Resolution 
    */

@media (max-width: 767.88px) {
  .yt-fp-top-back-tag-name {
    font-size: 14px;
    line-height: 1.14;
  }

  .yt-fp-form .form-group.yt-form-err span {
    bottom: -20px;
  }

  .yt-new-pass-form-wrap .yt-fp-btn {
    margin-top: 25px !important;
  }

  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-fp-btn {
    margin-top: 40px !important;
  }

  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-etpass-msg {
    bottom: 65px;
    top: 130px;
  }

  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-form-err:first-child {
    margin-bottom: 35px !important;
  }

  form.yt-new-pass-form-wrap.yt-form-wrap-err .yt-form-err:first-child span {
    top: 95%;
  }

  .yt-fp-inner-content.yt-form-col {
    padding: 15px;
  }

  .yt-forgot-pw-wrap {
    max-width: 335px;
  }

  .yt-fp-tag-line {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
  }

  .yt-fp-tag-line-2 {
    margin-bottom: 20px !important;
    font-size: 12px;
    line-height: 1.17;
  }

  .yt-fp-form .form-group .form-control {
    padding-top: 13px;
    padding-bottom: 12px;
  }

  .yt-fp-form {
    margin-bottom: 15px;
  }

  .yt-fp-oi-tag-small {
    font-size: 10px;
    line-height: 1.11;
  }

  .yt-fp-oi-tag-big {
    font-size: 30px;
    line-height: 1.13;
  }

  .yt-fp-bg-banner {
    min-height: 180px;
  }

  .yt-otp-sd-wrap {
    margin-top: 20px !important;
    padding: 0 !important;
  }

  .yt-otp-sd-wrap button {
    background: var(--button-primary) !important;
    color: #ffffff;
  }

  .yt-fp-btn {
    margin-top: 0 !important;
  }

  .yt-fp-skip-btn {
    font-size: 12px;
    line-height: 1.17;
  }

  .yt-forgot-pw-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .yt-fp-form .form-group.yt-form-err {
    margin-bottom: 28px !important;
  }

  .yt-fp-form .invalid.yt-form-err {
    bottom: -20px;
    font-size: 12px;
    line-height: 1.17;
  }
}

@media (max-width: 576px) {
  .yt-forgot-pw-wrap {
    max-width: 100%;
  }
}

@media (min-width: 1366px) and (max-width: 1900px) {
  .yt-fp-form .form-group {
    margin-bottom: 20px !important;
  }

  .yt-fp-btn {
    font-size: 16px;
    line-height: 1.13;
  }

  .yt-forgot-pw-wrap {
    padding: 26px 40px 34px;
  }

  .yt-fp-tag-line {
    margin-bottom: 34px;
    margin-top: 26px;
  }

  .yt-fp-tag-line-2 {
    margin-top: 0;
    margin-bottom: 30px !important;
  }

  .invalid {
    font-size: 12px;
    line-height: 1.17;
  }

  .invalid-feedback.yt-invalid-minimum-pass {
    margin: 0;
    top: 108%;
  }

  .yt-fp-form {
    margin-bottom: 20px !important;
  }

  .invalid-feedback {
    bottom: -17px;
  }

  .yt-fp-form .yt-new-pass-form-wrap .form-group.yt-form-err {
    margin-bottom: 36px !important;
  }

  .yt-new-pass-form-wrap .yt-fp-btn {
    margin-top: 35px !important;
  }

  .yt-fp-form .yt-new-pass-form-wrap .yt-form-confirm-err.form-group {
    margin-bottom: 50px !important;
  }

  form.yt-new-pass-form-wrap span.yt-etpass-msg {
    bottom: 68px;
  }

  form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group .invalid-feedback {
    bottom: -35px;
  }

  form.yt-new-pass-form-wrap .yt-form-confirm-err.form-group+span {
    bottom: 83px !important;
  }
}