@import url('./fonts/stylesheet.css');
@import url('../../../email-account-login/assets/css/login.css');
@import url('../../../ordersummary/assets/css/cart.css');
@import url('../../../ordersummary/assets/css/order.css');
@import url('../../../ordersummary/assets/css/order-reject.css');
@import url('../../../contactus/assets/css/contactus.web.css');
@import url('../../../contactus/assets/css/aboutus.web.css');
@import url('../../../forgot-password/assets/css/forgotPwd.css');
@import url('../../../settings/assets/css/faqs.web.css');
@import url('../../../settings/assets/css/helpcenters.web.css');
@import url('../../../settings/assets/css/reviews.css');
@import url('../../../otp-input-confirmation/assets/css/otpInput.css');
@import url('../../../user-profile-basic/assets/styles/profileIndex.css');
@import url('../../../user-profile-basic/assets/styles/userProfile.css');
@import url('../../../user-profile-basic/assets/styles/index.css');
@import url('../../../user-profile-basic/assets/styles/logoutModal.css');
@import url('../../../user-profile-basic/assets/styles/styles.css');
@import url('../../../ordersummary/assets/styles/orderDetails.css');
@import url('../../../ordersummary/assets/styles/talkUsModal.css');
@import url('../../../addressmanagement/assets/css/address.css');

:root {
  --color-primary: #3f7a4f;
  /* --color-dark-grey: #8b8f95; */
  --color-black: #1c213a;
  --color-dark-grey: #7c8188;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f67054;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-gray: #7c8188;
  --color-gold-yellow: #ffa500;
  --color-light-gray: #f2f6f8;

  /* BrandSetting Start */
  --button-primary: #3f7a4f;
  --button-text-primary: #ffffff;
  --button-primary-hover: #f67054;
  --button-text-primary-hover: #ffffff;
  --color-RegularActiveText: #3f7a4f;
  /* BrandSetting End */
}

* {
  font-family: 'Rubik';
}

body {
  background-color: #f6f8fa;
  padding-right: 0 !important;
  user-select: none;
}

.modal-content {
  border-radius: 10px;
}

@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #3fc1cb;

}

.carousel .control-dots .dot {
  border: 2px solid #3fc1cb;
  height: 15px;
  width: 15px;
  background: #FFFFFF;
}

/* Skeleton CSS */
.skeleton-bg .react-loading-skeleton {
  background: linear-gradient(90deg, #F5F5F5 0%, rgba(245, 245, 245, 0.3) 100%);
  --highlight-color: none
}

.item-category-img .react-loading-skeleton {
  height: 144px;
  width: 144px;
}

.skeleton-adv-bg .react-loading-skeleton {
  height: 250px;
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  --highlight-color: none;
  border-radius: 5px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.skeleton-banner-bg .react-loading-skeleton {
  height: 337px;
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  border-radius: 5px;
  --highlight-color: none
}

.skeleton-category-bg {
  text-align: center;
}

.skeleton-category-bg .react-loading-skeleton {
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  border-radius: 20px;
  width: 50%;
  height: 50px;
  --highlight-color: none
}

.skelton-empty-basket .react-loading-skeleton {
  background: linear-gradient(96.66deg, #DFDFDF 5.23%, rgba(245, 245, 245, 0.4) 94.77%);
  --highlight-color: none;
  height: 130px;
  width: 130px;
}

@media only screen and (min-width: 1024px) {
  .item-category-img .react-loading-skeleton {
    height: 100px;
    width: 100px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .item-category-img .react-loading-skeleton {
    height: 100px;
    width: 100px;
  }
}
